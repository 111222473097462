<template>
  <div><h1 class="heading">Правила начисления</h1></div>

  <text-block class="text-block">
    <p class="text-block-heading">Как заработать баллы?</p>
    <p class="text-block-text mb-0">Баллы можно получить, продав продукцию ТМ Ekko, Ekko by Maxonor, Virgo, Maxonor PURE LIFE, Maxonor PURE LIFE – SALON, затем отсканировав QR код на упаковке или разместив на странице «Заработать баллы» уникальный код, размещенный на наклейке с QR кодом.</p>
  </text-block>

  <text-block class="text-block">
    <p class="text-block-heading">Сколько баллов дается за продажи?</p>
    <p class="text-block-text mb-0">Размер бонусных баллов на начальном уровне составляет 4% от стоимости приобретения товара без учета НДС</p>

    <div class="mt-4">
        <p class="text-block-text mb-0">Для участников уровня Эксперт баллы умножаются на коэфф. К=1,2 и составят 4,8%</p>
        <p class="text-block-text mb-0">Для участников уровня Мастер баллы умножаются на коэфф. К=1,3 и составят 5,2%</p>
        <p class="text-block-text mb-0">Для участников уровня PRO баллы умножаются на коэфф. К=1,5 и составят 6%</p>
    </div>
  </text-block>

  <text-block class="text-block">
    <p class="text-block-heading">Когда баллы становятся доступными к списанию?</p>
    <p class="text-block-text mb-0">Начисленные баллы становятся доступными к списанию после оплаты магазином поставщику накладной по которой поступила продукция.</p>
    <p class="text-block-text mb-0">Размер бонусных баллов, возможных к списанию в течении календарного месяца, ограничивается размером 30% от начисленных и доступных к списанию бонусных баллов на начало текущего календарного месяца.</p>
  </text-block>

  <text-block class="text-block">
    <p class="text-block-heading">Как потратить баллы?</p>
    <p class="text-block-text mb-0">Потратить баллы можно в разделе Блага</p>
    <p class="text-block-text mb-0">На бонусные баллы можно приобрести сертификаты интернет-магазинов и розничных сетевых магазинов. Полный перечень возможных к приобретению сертификатов размещен на странице Блага.</p>
  </text-block>

  <text-block class="text-block">
    <p class="text-block-heading">Как перейти на уровень Эксперт, Мастер и PRO?</p>
    <p class="text-block-text mb-0">По итогам каждого квартала происходит присвоение уровня каждому участнику на основании суммы заработанных бонусов в квартал и количества проданных в квартал торговых марок</p>
    <p class="text-block-text mb-0">Переход на следующий уровень осуществляется при одновременном выполнении 2-х условий. По заработанным за квартал бонусам, по проданным торговым маркам.</p>

    <div class="desktop-table">
        <div class="table-block-heading d-none d-md-flex mt-3">
          <div class="col text-block-heading-blue text-md-center w-25">Уровень</div>
          <div class="col text-block-heading-blue text-md-center w-25">Повышающий коэффициент</div>
          <div class="col text-block-heading-blue text-md-center w-25">Количество заработанных бонусов в квартал</div>
          <div class="col text-block-heading-blue text-md-center w-25">Количество проданных торговых марок</div>
        </div>

        <div class="table-tm mt-3">
          <div class="table-block-wrapper pt-3">
            <div class="row mx-0 mb-2">
              <div class="col-md col-12 table-block-text text-md-center w-25">Новичок</div>
              <div class="col-md col-12 table-block-text text-md-center w-25"><span class="d-md-none">Повышающий коэффициент: </span>1</div>
              <div class="col-md col-12 table-block-text text-md-center w-25"><span class="d-md-none">Количество заработанных бонусов в квартал: </span>Дается при регистрации</div>
              <div class="col-md col-12 table-block-text text-md-center w-25"><span class="d-md-none">Количество проданных торговых марок: </span>Дается при регистрации</div>
            </div>
            <div class="row mx-0 mb-2">
              <div class="col-md col-12 table-block-text text-md-center w-25">Эксперт</div>
              <div class="col-md col-12 table-block-text text-md-center w-25"><span class="d-md-none">Повышающий коэффициент: </span>1,2</div>
              <div class="col-md col-12 table-block-text text-md-center w-25"><span class="d-md-none">Количество заработанных бонусов в квартал: </span>От 15 000 <br class="d-md-block d-none"/> <span class="table-condition">или <br class="d-md-block d-none"/></span> От 20000 </div>
              <div class="col-md col-12 table-block-text text-md-center w-25"><span class="d-md-none">Количество проданных торговых марок: </span>не менее 2 <br class="d-md-block d-none"/> <span class="table-condition">или <br class="d-md-block d-none"/></span> не менее 1 </div>
            </div>
            <div class="row mx-0 mb-2">
              <div class="col-md col-12 table-block-text text-md-center w-25">Мастер</div>
              <div class="col-md col-12 table-block-text text-md-center w-25"><span class="d-md-none">Повышающий коэффициент: </span>1,3</div>
              <div class="col-md col-12 table-block-text text-md-center w-25"><span class="d-md-none">Количество заработанных бонусов в квартал: </span>От 30 000 <br class="d-md-block d-none"/> <span class="table-condition">или <br class="d-md-block d-none"/></span> От 40 000 </div>
              <div class="col-md col-12 table-block-text text-md-center w-25"><span class="d-md-none">Количество проданных торговых марок: </span>не менее 3 <br class="d-md-block d-none"/> <span class="table-condition">или <br class="d-md-block d-none"/></span> не менее 2 </div>
            </div>
            <div class="row mx-0 mb-2">
              <div class="col-md col-12 table-block-text text-md-center w-25">PRO</div>
              <div class="col-md col-12 table-block-text text-md-center w-25"><span class="d-md-none">Повышающий коэффициент: </span>1,5</div>
              <div class="col-md col-12 table-block-text text-md-center w-25"><span class="d-md-none">Количество заработанных бонусов в квартал: </span> От 50 000 <br class="d-md-block d-none"/> <span class="table-condition">или <br class="d-md-block d-none"/></span> От 60 000 </div>
              <div class="col-md col-12 table-block-text text-md-center w-25"><span class="d-md-none">Количество проданных торговых марок: </span> не менее 4 <br class="d-md-block d-none"/> <span class="table-condition">или <br class="d-md-block d-none"/></span> не менее 3 </div>
            </div>
          </div>
        </div>
    </div>

    <p class="text-block-text mb-2">Участник уровня Новичок может по итогам квартала перейти на уровень эксперт, но не может сразу перейти на уровень Мастер или PRO</p>
    <p class="text-block-text mb-2">Участник уровня Эксперт может по итогам квартала перейти на уровень Мастер, но не может перейти сразу на уровень PRO. Может подтвердить уровень Эксперт, а также вернуться на уровень Новичок.</p>
    <p class="text-block-text mb-0">Участник уровня Мастер может по итогам квартала перейти на уровень PRO, может подтвердить уровень Мастер, а также вернуться на уровень Эксперт или Новичок</p>
    <p class="text-block-text mb-0">Присвоенные по итогам квартала уровни остаются закрепленными за участниками в течение всего квартала вне зависимости от текущих продаж.</p>

  </text-block>


  <text-block class="text-block">
    <p class="text-block-heading">Какие преимущества дает достижение уровней Эксперт, Мастер и PRO?</p>
    <p class="text-block-text mb-2">На уровне Эксперт все заработанные за продажу баллы увеличиваются на 20% но сравнению с начальным уровнем</p>
    <p class="text-block-text mb-2">На уровне Мастер все заработанные за продажу баллы увеличиваются на 30% но сравнению с начальным уровнем</p>
    <p class="text-block-text mb-2">На уровне PRO все заработанные за продажу баллы увеличиваются на 50% но сравнению с начальным уровнем</p>
    <p class="text-block-text mb-2">Увеличенные баллы участник уровня Эксперт, Мастер и PRO зарабатывает в течение всего квартала на который ему присвоен повышенный уровень</p>
  </text-block>





</template>

<script>
export default {
  name: "Rules"
}
</script>

<style scoped>
.table-condition {
  font-size:13px;
}
.desktop-table {
  display:block;
}
.mobile-table {
  display:none;
}
.text-block {
  margin-bottom:40px;
}
.text-block-heading {
  font-weight: bold;
  font-size: 16px;
}
.text-block-heading-blue {
  font-weight: bold;
  font-size: 14px;
  color: #3663F2;
}
.table-tm {
  margin-bottom:30px;
}
.table-block-heading {
  display:flex;
  justify-content: space-between;
  align-items:center;
  padding: 0 20px;
  margin-bottom:20px;
}
.table-production-name {
  padding-left:20px;
  margin-bottom:10px;
  font-weight: bold;
  font-size: 14px;
}
.table-block-wrapper {
  background-color: #e6eaf8;
  border-radius: 9px;
  padding:10px 20px;
}
.table-block-text {
  font-weight: bold;
  font-size: 14px;
  color: #171A1F;
}
.table-block-text span {
  display: none;
}
.table-value {
  font-weight: bold;
  font-size: 14px;
  color: #171A1F;
  width:100px;
  text-align:center;
}
.table-heading-value {
  width:100px;
  text-align:center;
}
@media (max-width: 768px) {
  .table-condition {
    font-size:11px;
  }
  .mobile-table {
    display:block;
  }
  .table-block-text {
    width:50%;
    font-size: 12px;
    text-align: left;
    margin-bottom: 1px;
  }
  .table-block-text span {
    display: inline;
  }
  .table-value-mobile {
    font-weight: bold;
    font-size: 12px;
    color: #171A1F;
    width:50%;
    text-align:center;
  }
}

</style>
